import React, { useState, useCallback, useRef, useEffect } from "react";

const useDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen((prev) => !prev), []);
  const close = useCallback(() => setIsOpen(false), []);
  return { isOpen, toggle, close };
};

const DropdownItem = ({ children, onClick, isActive }) => (
  <button
    className={`block w-full text-left px-4 py-2 text-sm text-textPrimary hover:bg-blue-200  ${
      isActive ? "bg-blue-100 text-blue-700" : ""
    }`}
    role="menuitem"
    onClick={onClick}
  >
    {children}
  </button>
);

const Dropdown = ({ label, isOpen, toggle, close, children }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [close]);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggle}
        className="flex items-center justify-between w-full text-textPrimary font-medium text-sm p-2 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-300"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {label}
        <svg
          className={`w-4 h-4 ml-2 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

const SuggestionBar = ({
  ruleTypes,
  handleRuleTypeChange,
  status,
  handleStatusChange,
}) => {
  const ruleTypeDropdown = useDropdown();
  const statusDropdown = useDropdown();

  return (
    <div className="flex items-center justify-between my-2 px-4">
      <div className="flex items-center space-x-4 font-semibold">Rules</div>
      <div className="flex items-center space-x-4">
        <Dropdown
          label="RULE TYPES"
          isOpen={ruleTypeDropdown.isOpen}
          toggle={ruleTypeDropdown.toggle}
          close={ruleTypeDropdown.close}
        >
          {Object.keys(ruleTypes).map((ruleType) => (
            <DropdownItem
              key={ruleType}
              onClick={() => handleRuleTypeChange(ruleType)}
              isActive={ruleTypes[ruleType]}
            >
              {ruleType}
            </DropdownItem>
          ))}
        </Dropdown>
        <Dropdown
          label="STATUSES"
          isOpen={statusDropdown.isOpen}
          toggle={statusDropdown.toggle}
          close={statusDropdown.close}
        >
          <DropdownItem
            onClick={() => handleStatusChange("all")}
            isActive={status === "all"}
          >
            All
          </DropdownItem>
          <DropdownItem
            onClick={() => handleStatusChange("active")}
            isActive={status === "active"}
          >
            Active
          </DropdownItem>
          <DropdownItem
            onClick={() => handleStatusChange("inactive")}
            isActive={status === "inactive"}
          >
            Inactive
          </DropdownItem>
        </Dropdown>
      </div>
    </div>
  );
};

export default SuggestionBar;
