import React, { useState, useRef, useEffect } from "react";
import { useKnowledgeBaseStore } from "../../../../../store";
import { useRulesStore } from "../../../../../store";
import { useNavigate } from "react-router-dom";
import { INTEGRATION_LIST } from "../../../../../utils/constant";
import { useIntegrationStore } from "store/integrationStore";

const MultiSelect = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleOption = (optionValue) => {
    const newValue = value.includes(optionValue)
      ? value.filter((v) => v !== optionValue)
      : [...value, optionValue];
    onChange(newValue);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="block w-full px-3 py-2 text-left border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      >
        {value.length > 0 ? `${value.length} selected` : "Select Actions"}
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          {options.length > 0 ? (
            options.map((option) => (
              <label
                key={option.name}
                className="flex items-center px-3 py-2 hover:bg-gray-100"
              >
                <input
                  type="checkbox"
                  checked={value.includes(option.name)}
                  onChange={() => toggleOption(option.name)}
                  className="mr-2"
                />
                {option.displayName}
              </label>
            ))
          ) : (
            <div className="px-3 py-2 text-gray-500">No actions available</div>
          )}
        </div>
      )}
    </div>
  );
};

const WorkflowComponent = () => {
  const [tools, setTools] = useState([{ name: "", actions: [] }]);
  const { integrations, fetchIntegrations } = useIntegrationStore();
  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);
  const [connectedIntegrations, setConnectedIntegrations] =
    useState(INTEGRATION_LIST);
  useEffect(() => {
    setConnectedIntegrations(
      INTEGRATION_LIST.filter((integration) =>
        integrations?.some((int) => int.name === integration.name),
      ),
    );
  }, [integrations]);
  // const { knowledgeBases } = useKnowledgeBaseStore();
  const knowledgeBases = useKnowledgeBaseStore((state) => state.knowledgeBases);
  const { form, updateForm } = useRulesStore();
  const navigate = useNavigate();

  const handleToolChange = (index, value) => {
    const newTools = tools.map((tool, i) => {
      if (i === index) {
        return { ...tool, name: value, actions: [] };
      }
      return tool;
    });
    setTools(newTools);
  };

  const handleActionChange = (index, newActions) => {
    const newTools = tools.map((tool, i) => {
      if (i === index) {
        return { ...tool, actions: newActions };
      }
      return tool;
    });
    setTools(newTools);
  };

  const addTool = () => {
    setTools([...tools, { name: "", actions: [] }]);
  };

  const getAvailableTools = (currentIndex) => {
    const selectedTools = tools
      .map((t) => t.name)
      .filter((t, i) => i !== currentIndex && t !== "");
    return connectedIntegrations.filter(
      (integration) => !selectedTools.includes(integration.name),
    );
  };
  useEffect(() => {
    if (form.tools?.length > 0) {
      setTools(
        form.tools.map((tool) => ({ name: tool.name, actions: tool.actions })),
      );
    } else {
      setTools([
        {
          name: "",
          actions: [],
        },
      ]);
    }
  }, []);
  useEffect(() => {
    const toolsWithActions = tools.filter((tool) => tool.actions.length > 0);
    updateForm("tools", toolsWithActions);
  }, [tools]);

  return (
    <div className="m-4">
      <p className="text-md font-semibold mb-2">Configure workflow</p>
      <div className="w-full p-4 bg-white rounded-lg border border-gray-300">
        <div className="flex items-center justify-between mb-2">
          <h1 className="text-sm font-medium">Tools</h1>
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-6 rounded-[2rem] transition-colors duration-150 ease-in-out"
            onClick={() => {
              navigate("/dashboard/integrations");
            }}
          >
            Connect Integration
          </button>
        </div>

        {tools.map((item, index) => (
          <div key={index} className="grid grid-cols-2 gap-6 mb-2">
            <div>
              {getAvailableTools(index).length === 0 ? (
                <select
                  disabled
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="" disabled selected hidden>
                    No available tools
                  </option>
                </select>
              ) : (
                <select
                  value={item.name}
                  onChange={(e) => handleToolChange(index, e.target.value)}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Select Tool</option>
                  {getAvailableTools(index).map((integration) => (
                    <option key={integration.name} value={integration.name}>
                      {integration.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div>
              <MultiSelect
                options={
                  connectedIntegrations.find(
                    (integration) => integration.name === item.name,
                  )?.implementedFunctionality || []
                }
                value={item.actions}
                onChange={(newActions) => handleActionChange(index, newActions)}
              />
            </div>
          </div>
        ))}

        <button
          onClick={addTool}
          className="pb-2 text-buttonBlue font-semibold"
          disabled={tools.length >= connectedIntegrations.length}
        >
          + Add Another
        </button>

        <div>
          <div className="flex items-center justify-between mb-2">
            <h1 className=" text-sm font-medium">Select knowledge base</h1>

            <button
              className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-6 rounded-[2rem] transition-colors duration-150 ease-in-out"
              onClick={() => navigate("/dashboard/knowledgeBase/create")}
            >
              Create Knowledge Base
            </button>
          </div>
          <select
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            name="knowledgeBase"
            value={form.knowledgeBaseId || ""}
            onChange={(e) => updateForm("knowledgeBaseId", e.target.value)}
          >
            {knowledgeBases.length > 0 ? (
              <>
                <option value="">Select Knowledge Base</option>
                {knowledgeBases.map((kb) => (
                  <option key={kb._id} value={kb._id}>
                    {kb.title}
                  </option>
                ))}
              </>
            ) : (
              <option value="" disabled>
                No Knowledge Base Entries
              </option>
            )}
          </select>
        </div>
      </div>
    </div>
  );
};

export default WorkflowComponent;
