import React from "react";
import { useRulesStore } from "../../../../../store";
import ReactQuill from "react-quill";

const RuleComponent = () => {
  const { form, updateForm } = useRulesStore();

  const addExpression = () => {
    updateForm("expressions", [...form.expressions, ""]);
  };

  const updateExpression = (index, value) => {
    const newExpressions = [...form.expressions];
    newExpressions[index] = value;
    updateForm("expressions", newExpressions);
  };

  const removeExpression = (index) => {
    const newExpressions = form.expressions.filter((_, i) => i !== index);
    updateForm("expressions", newExpressions);
  };

  return (
    <div className="m-4">
      <p className="text-md font-semibold mb-4">Rule Information</p>
      <div className="w-full mx-auto p-4  bg-white rounded-lg border border-gray-300">
        <div className="flex flex-row justify-between items-center">
          <div className="mb-4 mr-4 w-full">
            <label className="block text-sm font-medium text-textPrimary mb-1">
              Rule Name
            </label>
            <input
              type="text"
              value={form.name}
              onChange={(e) => updateForm("name", e.target.value)}
              placeholder="Inquiry about Product Sizing"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4 ml-4 w-full">
            <label className="block text-sm font-medium text-textPrimary mb-1">
              Rule Type
            </label>
            <select
              value={form.type}
              onChange={(e) => updateForm("type", e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="" defaultChecked>
                Select a rule type
              </option>
              <option value="assist">Assist</option>
              <option value="self-serve">Self-Serve</option>
              <option value="automation">Automation</option>
              <option value="HITL">HITL</option>
            </select>
          </div>
        </div>
        <div className="mb-14">
          <label className="block text-sm font-medium text-textPrimary mb-1">
            Rule Description
          </label>
          <ReactQuill
            value={form.description}
            onChange={(value) => updateForm("description", value)}
            placeholder="Describe what this rule is intended to do"
            className="h-24"
          />
        </div>

        <div className="mb-14">
          <label className="block text-sm font-medium text-textPrimary mb-1">
            Instruction
          </label>
          <ReactQuill
            value={form.instructions}
            onChange={(value) => updateForm("instructions", value)}
            placeholder="Provide instructions for this rule"
            className="h-24 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-textPrimary mb-1">
            Expressions
            <p className="text-sm text-textPrimary">
              Optional variations, words or phrases that the customer might use
              to describe this category of issues, inquiries or questions
            </p>
          </label>
          {form.expressions.map((expression, index) => (
            <div key={index} className="flex items-center mb-4">
              <ReactQuill
                value={expression}
                onChange={(value) => updateExpression(index, value)}
                placeholder="+ Add Expression"
                className="w-full"
              />
              <button
                onClick={() => removeExpression(index)}
                className="ml-2 px-2 py-1 text-red-600 font-semibold"
                type="button"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            onClick={addExpression}
            className="py-2 text-buttonBlue font-semibold"
            type="button"
          >
            + Add Expression
          </button>
        </div>
      </div>
    </div>
  );
};

export default RuleComponent;
