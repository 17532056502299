import { create } from "zustand";
import { persist } from "zustand/middleware";
import { updateUserInfoApi } from "../api/userApi";

const initialState = {
  isAuthenticated: false,
  token: localStorage.getItem("token") || null,
  user: null,
};

export const useAuthStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      login: (token, user, isAuthenticated) => {
        set({ token, user, isAuthenticated });
        localStorage.setItem("token", token);
      },
      resetAuth: () => {
        set(initialState);
        localStorage.removeItem("token");
      },
      updateAuth: (data) => {
        set((state) => ({ ...state, ...data }));
      },
      updateUserInfo: async () => {
        if (get().token === null) return;
        try {
          const response = await updateUserInfoApi();
          if (response.success) {
            set({ user: response.data });
          } else {
            throw new Error("Failed to update user info");
          }
        } catch (error) {
          console.log(error);
          set(initialState);
          localStorage.removeItem("token");
        }
      },
    }),
    {
      name: "auth",
    },
  ),
);
