import { create } from "zustand";
import WebSocketService from "../utils/websocketService";
import { useAuthStore } from "./authStore";

let wsInstance = null;

const useWebSocketStore = create((set, get) => ({
  isConnected: false,
  error: null,

  initializeWebSocket: (url) => {
    if (wsInstance) {
      console.log("WebSocket connection already exists");
      return;
    }

    // const { token } = useAuthStore.getState();
    // const fullUrl = `${url}?token=${token}`;

    wsInstance = new WebSocketService(url);
    wsInstance
      .connect()
      .then(() => set({ isConnected: true, error: null }))
      .catch((error) => {
        console.error("WebSocket connection failed:", error);
        set({
          error: "Failed to connect to the server. Please try again later.",
          isConnected: false,
        });
        wsInstance = null;
      });
  },

  sendMessage: async (message) => {
    if (!wsInstance || !wsInstance.isConnected()) {
      console.error("WebSocket is not connected");
      return;
    }
    wsInstance.sendMessage(JSON.stringify(message));
  },

  disconnect: () => {
    if (wsInstance) {
      wsInstance.disconnect();
      wsInstance = null;
      set({ isConnected: false });
    }
  },

  setMessageHandler: (handler) => {
    if (wsInstance) {
      wsInstance.onMessage(handler);
    }
  },
}));

export default useWebSocketStore;
