import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardHome from "./Home";
import Rules from "./Rules";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import KnowledgeBase from "./KnowlegeBase";
import { useKnowledgeBaseStore } from "store";
import { shallow } from "zustand/shallow";
import Responses from "./Responses";
import Tones from "./Tones";
import Integrations from "./Integrations";
const Dashboard = () => {
  const { fetchKnowledgeBases } = useKnowledgeBaseStore(
    (state) => ({
      fetchKnowledgeBases: state.fetchKnowledgeBases,
    }),
    shallow,
  );

  React.useEffect(() => {
    fetchKnowledgeBases();
  }, []);
  return (
    <>
      <Header />
      <Sidebar>
        <Routes>
          <Route path="/rules/*" element={<Rules />} />
          <Route path="/knowledgeBase/*" element={<KnowledgeBase />} />
          <Route path="/integrations/*" element={<Integrations />} />
          <Route path="/responses/" element={<Responses />} />
          <Route path="/tones/" element={<Tones />} />
          <Route path="*" element={<Navigate to="/dashboard/rules/" />} />
        </Routes>
      </Sidebar>
    </>
  );
};

export default Dashboard;
