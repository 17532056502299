export function getTimeAgoStringByDateString(dateString) {
  const date = new Date(dateString);
  const now = new Date().getTime();
  const diffInMs = now - date.getTime();

  // Calculate days, hours, and minutes
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const remainingHours = Math.floor(
    (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const remainingMinutes = Math.floor(
    (diffInMs % (1000 * 60 * 60)) / (1000 * 60),
  );

  // If less than a minute ago
  if (days === 0 && remainingHours === 0 && remainingMinutes === 0) {
    return "just now";
  }

  // If less than an hour ago, show minutes
  if (days === 0 && remainingHours === 0) {
    return `${remainingMinutes}m ago`;
  }

  // If less than a day ago, show hours
  if (days === 0) {
    return `${remainingHours}h ago`;
  }

  // Show days and hours
  const daysText = `${days}d`;
  const hoursText = remainingHours === 0 ? "" : ` ${remainingHours}h`;

  return `${daysText}${hoursText} ago`;
}
