import React from "react";
import TopBar from "../../../components/topBar";
import TopBarContent from "./components/topbarContent";
import HeroSection from "./components/heroSection";

const Responses = () => {
  return (
    <>
      <div className="h-screenHeight">
        <TopBar>
          <TopBarContent />
        </TopBar>
        <HeroSection />
      </div>
    </>
  );
};

export default Responses;
