import { useState } from "react";
import { register, googleRegister } from "../api/userApi";
import { useAuthStore } from "../store/authStore";
import { toast } from "react-toastify";

export const useSignup = () => {
  const auth = useAuthStore((state) => state);
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    name: "",
  });
  const [passwordStrength, setPasswordStrength] = useState({
    special: false,
    uppercase: false,
    lowercase: false,
    number: false,
  });
  const [error, setError] = useState("");
  const onError = (error) => {
    setError("Something went wrong");
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (
        formState.email === "" ||
        formState.password === "" ||
        formState.name === ""
      ) {
        // setError("Please fill all fields");
        toast.error("Please fill all fields");
        return;
      }
      if (
        passwordStrength.special === false ||
        passwordStrength.uppercase === false ||
        passwordStrength.lowercase === false ||
        passwordStrength.number === false
      ) {
        // setError("Password is Weak");
        toast.error("Password is Weak");
        return;
      }
      const response = await register(
        formState.email,
        formState.name,
        formState.password,
      );
      console.log(response);
      if (response.success) {
        auth.login(response.data.token, response.data.user, true);
      } else {
        console.log(response);
        toast.error(response?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
  };
  const handleGoogleLogin = async (payload) => {
    try {
      console.log(payload);
      const response = await googleRegister(
        payload.access_token || payload.credential,
      );
      if (response.success) {
        auth.login(response.data.token, response.data.user, true);
      } else {
        // setError(response?.message);
        toast.error(response?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
  };
  return {
    formState,
    setFormState,
    passwordStrength,
    setPasswordStrength,
    error,
    setError,
    onError,
    handleLogin,
    handleGoogleLogin,
  };
};
