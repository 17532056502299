import React, { useEffect } from "react";
import TopBar from "../components/TopBar";
import KnowledgeBaseCreationForm from "../components/KnowledgeBaseCreationForm"; // Updated to KnowledgeCreationForm
import TopBarContent from "./components/TopBarContent";
import { useNavigate, useParams } from "react-router-dom";
import { useKnowledgeBaseStore } from "../../../../store"; // Updated to KnowledgeBaseStore
import { updateKnowledgeBaseApi } from "../../../../api/knowledgeBaseApi"; // Updated to KnowledgeBaseApi
import { shallow } from "zustand/shallow";
const EditKnowledgeBaseEntry = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    knowledgeBases,
    form,
    resetForm,
    fetchKnowledgeBases,
    updateFullForm,
    checkFormFilled,
  } = useKnowledgeBaseStore(
    (state) => ({
      knowledgeBases: state.knowledgeBases,
      form: state.form,
      resetForm: state.resetForm,
      fetchKnowledgeBases: state.fetchKnowledgeBases,
      updateFullForm: state.updateFullForm,
      checkFormFilled: state.checkFormFilled,
    }),
    shallow,
  );

  useEffect(() => {
    if (id === undefined || id === "") {
      resetForm();
      navigate("/dashboard/knowledgeBase");
    }
    const entry = knowledgeBases?.find((entry) => entry._id === id);
    if (entry) {
      updateFullForm(entry);
    } else {
      resetForm();
      navigate("/dashboard/knowledgeBase");
    }
    return () => {
      resetForm();
    };
  }, [id, knowledgeBases, navigate, resetForm, updateFullForm]);

  const handleSaveEntry = async () => {
    try {
      const isFormFilled = checkFormFilled();
      if (!isFormFilled) {
        return;
      }
      await updateKnowledgeBaseApi(form, id);
      resetForm();
      fetchKnowledgeBases();
      navigate("/dashboard/knowledgeBase");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <TopBar>
        <TopBarContent handleSaveEntry={handleSaveEntry} />
      </TopBar>
      <KnowledgeBaseCreationForm />
    </>
  );
};

export default EditKnowledgeBaseEntry;
