import React from "react";
import { PersonCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import GreenKimLogo from "../../assets/kim-white.svg";
import { useAuthStore } from "../../store/index";
import Modal from "./modal";
const Header = () => {
  const auth = useAuthStore((state) => state);
  const navigate = useNavigate();
  const [openLogoutDiv, setOpenLogoutDiv] = React.useState(false);

  const openModal = () => {
    console.log("toogle");
    // setOpenLogoutDiv((prev) => !prev);
    if (!openLogoutDiv) setOpenLogoutDiv(true);
  };
  const closeModal = () => {
    console.log("close");
    if (openLogoutDiv) setOpenLogoutDiv(false);
  };

  const handleLogout = () => {
    auth.resetAuth();
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div className="bg-primaryColor text-white flex items-center p-4 w-full">
      <div className="flex-[0.2]">
        <img alt="kim-logo" src={GreenKimLogo} className="h-6" />
      </div>
      <div className="flex-1 text-right text-md relative">
        <div className="flex items-center justify-end relative">
          <div className="mr-4 capitalize">{auth?.user?.name}</div>
          <PersonCircle
            width={32}
            height={32}
            className="cursor-pointer"
            onClick={openModal}
          />
        </div>
        <Modal
          show={openLogoutDiv}
          onClose={closeModal}
          handleLogout={handleLogout}
        />
      </div>
    </div>
  );
};

export default Header;
