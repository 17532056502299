import React from "react";
import { useNavigate, Link } from "react-router-dom";

const TopBarContent = ({ handleTestRule }) => {
  return (
    <>
      {" "}
      <div>
        <Link
          to="/dashboard/rules"
          className="text-2xl font-medium  text-blue-700"
        >
          Rule Library
        </Link>
        <span className="text-xl font-semibold text-gray-800">{" > "}</span>
        <Link
          to="/dashboard/rules/create"
          className="text-2xl font-bold text-gray-800 "
        >
          View Rule
        </Link>
      </div>
      <button
        className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-6 rounded-[2rem] transition-colors duration-150 ease-in-out"
        onClick={handleTestRule}
      >
        Test Rule
      </button>
    </>
  );
};

export default TopBarContent;
