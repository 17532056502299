import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SignupBackground from "../../assets/signupBackground.png";
import transparentLogo from "../../assets/kim-logo-transparent.png";
import { Link } from "react-router-dom";
import SignupForm from "./components/SignupForm";
const backgroundStyle = {
  backgroundImage: `url(${SignupBackground})`,
  backgroundSize: "cover",
};

const Login = (props) => {
  return (
    <div
      style={backgroundStyle}
      className="flex justify-center items-center min-h-screen font-inter"
    >
      <div className="flex justify-center items-center xs:w-full w-[38.88%] bg-white px-[2.77%] py-[5.54%]">
        <div className="flex flex-col justify-center items-center w-full">
          <img
            src={transparentLogo}
            alt="logo"
            className="w-[7.5rem] h-[2.2rem]"
          />
          <h1 className="text-[1.875rem] text-[#444] font-inter font-[600] text-center my-10">
            Create Your Account
          </h1>
          <SignupForm />
          <p className="mt-5">
            Already have an account?{" "}
            <Link to="/login" className="text-ascentColor underline">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
