import React, { useState, useEffect } from "react";
import QuestionBox from "./questionBox";
import { getAllResponsesApi } from "api/responseApi";

const HeroSection = () => {
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        const { data } = await getAllResponsesApi();
        setResponses(data);
      } catch (error) {
        console.error("Error fetching responses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResponses();
  }, []);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    responses.length > 0
      ? responses.slice(indexOfFirstItem, indexOfLastItem)
      : [];

  // Change page
  const paginate = (pageNumber) => {
    // Ensure we do not navigate to a page without items
    if (pageNumber <= Math.ceil(responses.length / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="flex flex-row justify-between m-4">
      <div className="bg-white w-full p-4">
        <p className="font-semibold text-lg">Questions</p>
        {loading ? (
          <div className="text-center">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p className="mt-2 text-gray-600">Loading responses...</p>
          </div>
        ) : (
          <>
            {currentItems.length === 0 && (
              <p className="text-center text-gray-500">
                No responses currently found. Please check back later.
              </p>
            )}
            {currentItems.map((response, index) => (
              <QuestionBox
                key={response.id}
                sno={indexOfFirstItem + index + 1}
                question={response.question}
                answer={response.answer}
                id={response.id}
              />
            ))}
            {/* Pagination Controls */}
            {responses.length > itemsPerPage && ( // Only show pagination if more than one page is needed
              <div className="flex justify-center mt-4">
                {Array.from(
                  { length: Math.ceil(responses.length / itemsPerPage) },
                  (_, i) => (
                    <button
                      key={i + 1}
                      onClick={() => paginate(i + 1)}
                      className={`mx-1 px-4 py-2 rounded ${currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-gray-200"}`}
                    >
                      {i + 1}
                    </button>
                  ),
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HeroSection;
