import React from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { login, googleLogin } from "../../../../api/userApi";
import { useAuthStore } from "../../../../store/authStore";
import { useState } from "react";
import { toast } from "react-toastify";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = useAuthStore((state) => state);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (email === "" || password === "") {
        toast.error("Please fill all fields");
        return;
      }
      const response = await login(email, password);
      console.log(response);
      if (response.success) {
        toast.success("Login Successful", { autoClose: 2000 });
        auth.login(response.data.token, response.data.user, true);
        navigate("/dashboard");
      } else {
        console.log(response);
        toast.error(response?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      setError("Something went wrong");
    }
  };
  const handleGoogleLogin = async (payload) => {
    try {
      const response = await googleLogin(payload.credential);
      if (response.success) {
        auth.login(response.data.token, response.data.user, true);
        navigate("/dashboard");
      } else {
        toast.error(response?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      // setError("Something went wrong");
      toast.error("Something went wrong");
    }
  };
  return (
    <>
      <form className="mt-8 space-y-6 w-full" onSubmit={handleSubmit}>
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="focus:outline-none appearance-none relative block w-full px-3 py-2 border border-[#444] placeholder-gray-500 text-gray-900 rounded-md"
              placeholder="your company email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
            />
          </div>
          <div className="pt-[1.25rem]">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="focus:outline-none appearance-none relative block w-full px-3 py-2 border border-[#444] placeholder-gray-500 text-gray-900 rounded-md"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError("");
              }}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm rounded-md text-[#444] bg-[#fabf01] font-bold"
          >
            SIGN IN
          </button>
        </div>

        {/* <div className="flex items-center my-8">
          <div className="flex-grow h-[1px] bg-black"></div>
          <span className="mx-4 text-center text-[#444] text-xl font-medium">
            OR
          </span>
          <div className="flex-grow h-[1px] bg-black"></div>
        </div>

        <div className="flex justify-center">
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={(res) => {
              console.log("error", res);
            }}
            useOneTap
            auto_select
          >
            SignIn with Google
          </GoogleLogin>
        </div> */}
      </form>
      {error ? <p className=" text-red-400">{error}</p> : ""}
    </>
  );
};

export default LoginForm;
