// Modal.js
import React, { useRef, useEffect } from "react";

function Modal({ show, onClose, handleLogout }) {
  const modalRef = useRef();

  // Close modal if clicked outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && show) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div
      className="absolute right-0 mt-2 w-32 bg-white rounded-lg shadow-lg z-20 opacity-100"
      ref={modalRef}
    >
      <button
        className="p-1 pr-3 text-black text-2xl"
        onClick={onClose} // Ensure this function handles the closing of the modal
      >
        &times; {/* Using the multiplication symbol as a close icon */}
      </button>
      <button
        className="text-black font-semibold flex items-center justify-center h-10 pb-1 w-full rounded-lg"
        onClick={handleLogout}
      >
        LOGOUT
      </button>
    </div>
  );
}

export default Modal;
