export const capitalizeWords = (str = "") => {
  if (!str) {
    return null;
  }
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export function decodeMessage(encodedMessage) {
  try {
    // Create a TextDecoder instance
    const decoder = new TextDecoder("utf-8");

    // Convert the encoded message into a Uint8Array
    const byteArray = new Uint8Array(
      encodedMessage.split("").map((char) => char.charCodeAt(0)),
    );

    // Decode the byteArray into a properly formatted string
    const decodedMessage = decoder.decode(byteArray);

    return decodedMessage;
  } catch (error) {
    console.error("Error decoding message:", error);
    return null;
  }
}
