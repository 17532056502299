import React, { useEffect, useState } from "react";
import { useKnowledgeBaseStore } from "../../../../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { getDownloadUrl } from "api/awsApi";

const ParameterInput = ({ parameter }) => {
  const [isLoading, setIsLoading] = useState(false);

  const parseValue = (value) => {
    if (typeof value !== "string") {
      console.warn("Expected string value for parameter:", parameter);
      return value;
    }
    try {
      return JSON.parse(value);
    } catch (error) {
      console.error("Error parsing parameter value:", error);
      return value;
    }
  };

  const handleViewFile = async (key) => {
    setIsLoading(true);
    try {
      const downloadUrl = await getDownloadUrl(key);
      window.open(downloadUrl, "_blank");
    } catch (error) {
      console.error("Error getting download URL:", error);
      alert("Failed to get download URL");
    } finally {
      setIsLoading(false);
    }
  };

  const renderValue = () => {
    if (!parameter || typeof parameter !== "object") {
      console.error("Invalid parameter:", parameter);
      return "Invalid parameter";
    }

    const parsedValue = parseValue(parameter.value);

    switch (parameter.type) {
      case "WEBPAGE":
        return parsedValue?.url || "No URL provided";
      case "TEXT_INPUT":
        return parsedValue?.text || "No text provided";
      case "WEBSITE":
        return (
          <div>
            <p>Base URL: {parsedValue?.base_url || "Not provided"}</p>
            <p>Prefix URL: {parsedValue?.prefix_url || "Not provided"}</p>
            <p>Max Depth: {parsedValue?.max_depth ?? "Not provided"}</p>
          </div>
        );
      case "FILE_UPLOAD":
        return (
          <div>
            <button
              onClick={() => handleViewFile(parsedValue?.key)}
              disabled={isLoading}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
            >
              {isLoading ? "Loading..." : "View File"}
            </button>
          </div>
        );
      default:
        return typeof parsedValue === "object"
          ? JSON.stringify(parsedValue)
          : String(parsedValue);
    }
  };

  if (!parameter || !parameter.type) {
    return null; // or some fallback UI
  }

  return (
    <div className="mb-4 p-4 bg-gray-100 rounded-lg shadow-sm">
      <div className="w-full px-3 py-2 mb-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500">
        {parameter.type}
      </div>
      <div className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500">
        {renderValue()}
      </div>
    </div>
  );
};

const KnowledgeCreationForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { form, updateForm } = useKnowledgeBaseStore(
    (state) => ({
      form: state.form,
      updateForm: state.updateForm,
    }),
    shallow,
  );
  const [parameters, setParameters] = useState([]);

  useEffect(() => {
    if (form.parameters && Array.isArray(form.parameters)) {
      setParameters(form.parameters);
    } else {
      console.warn("Invalid or missing parameters in form:", form);
      setParameters([]);
    }
  }, [form.parameters]);

  return (
    <div className="m-4">
      <div className="w-full flex items-end justify-between">
        <p className="text-md font-semibold mb-2">
          Knowledge Base Entry Information
        </p>
        <button
          className="px-4 py-2 text-buttonBlue font-semibold cursor-not-allowed opacity-50"
          onClick={() => navigate(`/dashboard/knowledgeBase/edit/${id}`)}
          disabled
          aria-disabled="true"
        >
          Edit Entry
        </button>
      </div>
      <div className="w-full mx-auto p-4 bg-white rounded-lg border border-gray-300">
        <div className="mb-6">
          <label className="block text-sm font-medium text-textPrimary">
            Title
          </label>
          <input
            type="text"
            value={form.title}
            onChange={(e) => updateForm("title", e.target.value)}
            placeholder="Enter the title of the knowledge base entry"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            disabled
          />
        </div>

        <div>
          {parameters.map((parameter, index) => (
            <ParameterInput key={index} parameter={parameter} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default KnowledgeCreationForm;
