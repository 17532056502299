import React from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

const TopBarContent = ({ handleSaveEntry }) => {
  const { id } = useParams();
  return (
    <>
      <div>
        <Link
          to="/dashboard/knowledgeBase"
          className="text-2xl font-medium text-gray-800 underline underline-offset-4"
        >
          Knowledge Base
        </Link>
        <span className="text-2xl font-medium text-gray-800">{" < "}</span>
        <Link
          to={`/dashboard/knowledgeBase/edit/${id}`}
          className="text-2xl font-bold text-blue-700"
        >
          Edit Entry
        </Link>
      </div>
      <button
        className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-6 rounded-[2rem] transition-colors duration-150 ease-in-out"
        onClick={handleSaveEntry}
      >
        Save Entry
      </button>
    </>
  );
};

export default TopBarContent;
