import baseApi from "./baseApi";
import { useAuthStore } from "../store";
export const fetchKnowledgeBaseApi = async () => {
  const auth = useAuthStore.getState();
  const token = auth.token;
  const accountId = auth.user.accountId;
  try {
    const response = await baseApi.get(`/knowledgeBase/brand/${accountId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createKnowledgeBaseEntryApi = async (data) => {
  const auth = useAuthStore.getState();
  const token = auth.token;
  try {
    const response = await baseApi.post(
      "/knowledgeBase",
      {
        ...data,
        accountId: auth.user.accountId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error("Error creating knowledge base entry");
    }

    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateKnowledgeBaseApi = async (data, id) => {
  const auth = useAuthStore.getState();
  const token = auth.token;
  try {
    const response = await baseApi.put(`/knowledgeBase/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error updating knowledge base entry");
    }

    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteKnowledgeBaseEntryApi = async (id) => {
  const { token } = useAuthStore.getState();
  const response = await baseApi.delete(`/knowledgeBase/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (!response.ok) {
    const jsonRes = await response.json();
    throw new Error(jsonRes.message || "Error deleting knowledge base entry");
  }

  return response.json();
};
