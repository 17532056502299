import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    if (window.history.length > 1 && location.state?.from) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="text-center p-10 rounded-lg bg-white shadow-lg">
        <h1 className="text-9xl font-bold text-gray-800">404</h1>
        <h2 className="text-2xl font-medium text-gray-600 mt-4">
          Page Not Found
        </h2>
        <p className="text-textPrimary mt-4">
          The page you are looking for does not exist.
        </p>
        <button
          onClick={handleGoBack}
          className="mt-6 px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-700 transition-colors"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
