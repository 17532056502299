import React, { useEffect, useState } from "react";
import { useRulesStore } from "../../../../../../store";
import { useParams, useNavigate } from "react-router-dom";
const RuleComponent = () => {
  const { form } = useRulesStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const [expressions, setExpressions] = useState([""]);
  useEffect(() => {
    setExpressions(form.expressions);
  }, [form.expressions]);

  return (
    <div className="m-4">
      <div className="w-full flex items-end justify-between">
        <p className="text-md font-semibold mb-2">Rule Information</p>
        <button
          className="px-4 py-2 text-buttonBlue font-semibold"
          onClick={() => navigate(`/dashboard/rules/edit/${id}`)}
        >
          Edit Rule
        </button>
      </div>
      <div className="w-full mx-auto p-4  bg-white rounded-lg border border-gray-300">
        <div className="flex flex-row justify-between items-center">
          <div className="mb-2 mr-4 w-full">
            <label className="block text-sm font-medium text-textLight">
              Rule Name
            </label>
            <div className="w-full py-2 text-textPrimary text-sm font-medium">
              {" "}
              {form.name}{" "}
            </div>
          </div>
          <div className="mb-2 ml-4 w-full">
            <label className="block text-sm font-medium text-textLight">
              Rule Type
            </label>
            <div className="w-full py-2 text-textPrimary text-sm font-medium">
              {" "}
              {form.type}
            </div>
          </div>
        </div>
        <div className="mb-2">
          <label className="block text-sm font-normal text-textLight">
            Rule Description
          </label>
          <div
            className="w-full py-2 text-textPrimary text-sm font-medium"
            dangerouslySetInnerHTML={{ __html: form.description }}
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm font-normal text-textLight">
            Rule Instructions
          </label>
          <div
            className="w-full py-2 text-textPrimary text-sm font-medium"
            dangerouslySetInnerHTML={{ __html: form.instructions }}
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm font-medium text-textLight my-2">
            Expressions
          </label>
          {expressions.length > 0
            ? expressions.map((expression, index) => (
                <div
                  key={index}
                  className="w-full py-2 text-textPrimary text-sm font-medium"
                  dangerouslySetInnerHTML={{ __html: expression }}
                />
              ))
            : "-"}
        </div>
      </div>
    </div>
  );
};

export default RuleComponent;
