import React from "react";
import { useNavigate } from "react-router-dom";

const TopBarContent = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-row items-center">
        <p className="text-2xl font-semibold text-buttonBlue font-onest">
          Knowledge Base
        </p>
        <p className="text-md text-textPrimary p-1 pl-3">
          manage, review, and edit your knowledge base entries
        </p>
      </div>
      <button
        className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-6 rounded-[2rem] transition-colors duration-150 ease-in-out"
        onClick={() => {
          navigate("/dashboard/knowledgeBase/create");
        }}
      >
        Create Entry
      </button>
    </>
  );
};

export default TopBarContent;
