import GorgiasLogo from "../assets/gorgias-logo.png";
export const INTEGRATION_LIST = [
  {
    name: "shopify",
    logo: "https://cdn.worldvectorlogo.com/logos/shopify.svg",
    isConnected: false,
    requiredFields: [
      {
        displayName: "Shop Name",
        name: "shop",
        value: "",
        userInputRequired: false,
      },
    ],
    implementedFunctionality: [
      {
        name: "shopifyOrderStatusById",
        displayName: "Shopify Order Details by Order ID",
      },
      {
        name: "shopifyOrderStatusByName",
        displayName: "Shopify Order Details by Order Name",
      },
    ],
  },
  {
    name: "notion",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png",
    isConnected: false,
    requiredFields: [
      { displayName: "Integration Token", name: "integrationToken", value: "" },
    ],
  },
  {
    name: "gorgias",
    logo: GorgiasLogo,
    isConnected: false,
    requiredFields: [{ displayName: "Shop Name", name: "shop", value: "" }],
  },
];

export const KNOWLEDGE_BASE_FILE_TYPES = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DocxReader
  "application/epub+zip", // EpubReader
  "application/x-hwp", // HWPReader
  "image/jpeg", // ImageReader
  "application/x-ipynb+json", // IPYNBReader
  "text/markdown", // MarkdownReader
  "application/vnd.mozilla.mbox", // MboxReader
  "text/csv", // PandasCSVReader
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // PandasExcelReader
  "application/pdf", // PDFReader
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PptxReader
  "video/mp4", // VideoAudioReader
  "application/json", // JSON files
  "image/png", // PNG images
];
