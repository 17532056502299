import React from "react";
import { useGoogleLogin } from "@react-oauth/google";

const MyCustomButton = ({ handleGoogleLogin, onError }) => {
  const login = useGoogleLogin({
    onSuccess: handleGoogleLogin,
    onError: onError,
  });

  return (
    <button
      onClick={() => login()}
      className="w-full px-4 py-2  text-lightGray rounded-md text-lg flex items-center justify-center border border-black-1 hover:bg-gray-100 hover:border-gray-100 hover:text-black-1"
    >
      <svg width="20" height="20" viewBox="0 0 48 48" className="mr-2">
        <path
          fill="#4285F4"
          d="M24 9.5c3.9 0 6.9 1.6 9.1 3.6l6.8-6.8C35.6 2.3 30.1 0 24 0 14.6 0 6.4 5.3 2.6 12.9l7.7 6.1C12.4 12.8 17.8 9.5 24 9.5z"
        />
        <path
          fill="#34A853"
          d="M46.5 24c0-1.7-.1-3.3-.4-4.8H24v9.1h12.7c-.6 3.4-2.6 6.3-5.4 8.2l7.7 6c4.5-4.1 7.2-10.2 7.2-18.5z"
        />
        <path
          fill="#FBBC05"
          d="M12.3 28.5c-.8-2.4-1.3-4.9-1.3-7.5s.5-5.1 1.3-7.5l-7.7-6C2.7 12.9 0 18.3 0 24s2.7 11.1 7.4 14.5l7.7-6z"
        />
        <path
          fill="#EA4335"
          d="M24 48c6.1 0 11.2-2 14.9-5.4l-7.7-6.1c-2.1 1.4-4.7 2.2-7.2 2.2-5.5 0-10.1-3.7-11.8-8.8l-7.7 6C6.4 42.7 14.6 48 24 48z"
        />
      </svg>
      Signup in with Google
    </button>
  );
};

export default MyCustomButton;
