import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import CopilotSvg from "../../assets/VectorCOpilot.svg";
import { LinkObj } from "./constant";
import { capitalizeWords } from "utils";

const SideBar = ({ children }) => {
  const [expandedMenu, setExpandedMenu] = useState("copilot");

  const toggleMenu = (e, menuItemName) => {
    e.preventDefault();
    e.stopPropagation();
    setExpandedMenu(expandedMenu === menuItemName ? "" : menuItemName);
  };
  const linkClassName = (isActive) =>
    `flex items-center cursor-pointer text-sm no-underline ${
      isActive
        ? "text-white bg-highlightColor font-bold p-4 pl-8"
        : "text-[#9F9F9F] font-normal bg-transparent py-4 pl-8"
    }`;
  return (
    <div className="flex w-full overflow-y-hidden h-screenHeight">
      <div className="w-[200px] overflow-hidden bg-primaryColor">
        <div className="gap-1 grid grid-rows-1">
          <div>
            <div
              className={`flex items-center justify-between text-sm font-medium cursor-pointer p-4 no-underline ${
                expandedMenu === "copilot"
                  ? "text-white font-bold bg-highlightColor"
                  : "text-[#9F9F9F] bg-transparent"
              }`}
            >
              <Link to="/dashboard" className="flex items-center flex-grow">
                <span className="pl-2 flex gap-1">
                  <img src={CopilotSvg} alt="Copilot" />
                  Copilot
                </span>
              </Link>
              <button
                onClick={(e) => toggleMenu(e, "copilot")}
                className="bg-transparent border-none cursor-pointer p-0"
              >
                {expandedMenu === "copilot" ? <ChevronUp /> : <ChevronDown />}
              </button>
            </div>
            {expandedMenu === "copilot" &&
              LinkObj.map((menuItem, idx) => (
                <NavLink
                  key={menuItem.name}
                  to={menuItem.path}
                  className={({ isActive }) => linkClassName(isActive)}
                >
                  {capitalizeWords(menuItem.name)}
                </NavLink>
              ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-screenHeight">
        <div className="flex-grow h-full overflow-y-auto bg-bgColor">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
