import React from "react";
const TopBarContent = () => {
  return (
    <>
      {" "}
      <div className="flex flex-row items-center">
        <p className="text-2xl font-semibold text-buttonBlue font-onest">
          Tones Library
        </p>
        <p className="text-md text-textPrimary p-1 pl-3">
          Review and customize your generated responses tone
        </p>
      </div>
    </>
  );
};

export default TopBarContent;
