import React, { useState, useEffect } from "react";
import { getAllTonesApi, updateToneApi } from "api/toneApi";
import { toast } from "react-toastify";

const MainSection = () => {
  const [tones, setTones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingTone, setEditingTone] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");

  useEffect(() => {
    const fetchTones = async () => {
      try {
        const { data } = await getAllTonesApi();
        setTones(data);
      } catch (error) {
        console.error("Error fetching tones:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTones();
  }, []);

  const handleEditClick = (tone) => {
    setEditingTone(tone._id);
    setEditedDescription(tone.description);
  };

  const handleSaveClick = async (_id) => {
    try {
      await updateToneApi(_id, editedDescription);
      toast.success("Tone updated successfully");
      setTones(
        tones.map((tone) =>
          tone._id === _id ? { ...tone, description: editedDescription } : tone,
        ),
      );
      setEditingTone(null);
    } catch (error) {
      toast.error("Error updating tone");
      console.error("Error updating tone:", error);
    }
  };

  const handleCancelClick = () => {
    setEditingTone(null);
    setEditedDescription("");
  };

  return (
    <div className="flex flex-col m-4">
      <div className="bg-white w-full p-4 rounded-lg shadow">
        <h2 className="font-semibold text-lg mb-4">Tones</h2>
        {loading ? (
          <div className="text-center">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <p className="mt-2 text-gray-600">Loading tones...</p>
          </div>
        ) : (
          <div className="space-y-4">
            {tones.map((tone) => (
              <div key={tone._id} className="border-b border-gray-200 pb-4">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-semibold capitalize">{tone.channel}</h3>
                  {editingTone === tone._id ? (
                    <div>
                      <button
                        onClick={() => handleSaveClick(tone._id)}
                        className="text-green-600 hover:text-green-800 mr-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={handleCancelClick}
                        className="text-red-600 hover:text-red-800"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => handleEditClick(tone)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      Edit
                    </button>
                  )}
                </div>
                {editingTone === tone._id ? (
                  <textarea
                    value={editedDescription}
                    onChange={(e) => setEditedDescription(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded"
                    rows={3}
                  />
                ) : (
                  <p className="text-gray-600">{tone.description}</p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MainSection;
