import baseApi from "./baseApi";
import { useAuthStore } from "../store";

export const getAllTonesApi = async () => {
  const {
    token,
    user: { accountId },
  } = useAuthStore.getState();

  try {
    const response = await baseApi.get(`/tone/account/${accountId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonRes = await response.json();
    if (!response.ok)
      throw new Error(jsonRes.message || "Failed to fetch tones");
    return jsonRes;
  } catch (err) {
    console.error("Error fetching tones:", err);
    throw err;
  }
};

export const updateToneApi = async (id, description) => {
  const { token } = useAuthStore.getState();

  try {
    const response = await baseApi.put(
      `/tone/${id}`,
      { description },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const jsonRes = await response.json();
    if (!response.ok)
      throw new Error(jsonRes.message || "Failed to update rule");
    return jsonRes;
  } catch (err) {
    console.error("Error updating rule:", err);
    throw err;
  }
};
