export const LinkObj = [
  {
    name: `Knowledge Base`,
    path: "/dashboard/knowledgeBase",
    subMenu: [],
  },
  {
    name: "Rule Library",
    path: "/dashboard/rules",
    subMenu: [],
  },
  {
    name: "Integrations",
    path: "/dashboard/integrations",
    subMenu: [],
  },
  {
    name: "Tones",
    path: "/dashboard/tones",
    subMenu: [],
  },
  {
    name: "Responses",
    path: "/dashboard/responses",
    subMenu: [],
  },
];
