import baseApi from "./baseApi";
import { useAuthStore } from "../store";

export const getPresignedUrl = async (key) => {
  const { token } = useAuthStore.getState();
  const response = await baseApi.get(
    `/aws/s3/generate-presigned-url?key=${key}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (!response.ok) {
    throw new Error("Failed to get presigned URL");
  }
  const data = await response.json();
  return data.data;
};

export const uploadFileToS3 = async (url, file) => {
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "binary/octet-stream",
    },
    body: file,
  });

  if (!response.ok) {
    throw new Error("Failed to upload file to S3");
  }
};

export const processFile = async (key) => {
  const response = await baseApi.post("/aws/s3/process-file", { key });
  if (!response.ok) {
    throw new Error("Failed to process file");
  }
};

export const getDownloadUrl = async (key) => {
  const { token } = useAuthStore.getState();

  const response = await baseApi.post(
    "/aws/s3/generate-download-url",
    { key },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (!response.ok) {
    throw new Error("Failed to get download URL");
  }
  const data = await response.json();
  return data.data.url;
};

export const getDeleteUrl = async (key) => {
  const response = await baseApi.post("/aws/s3/generate-delete-url", { key });
  if (!response.ok) {
    throw new Error("Failed to get delete URL");
  }
  const data = await response.json();
  return data.data.url;
};

export const deleteFileFromS3 = async (url) => {
  const response = await fetch(url, {
    method: "DELETE",
  });
  if (!response.ok) {
    throw new Error("Failed to delete file from S3");
  }
};
