import React from "react";

const TopBar = ({ children }) => {
  return (
    <div className="flex flex-row w-full bg-white items-center justify-between px-5 py-4 gap-5 shrink-0 border-b border-b-disableColor border-t border-t-disableColor sticky top-0 z-10">
      {children}
    </div>
  );
};

export default TopBar;
