import { create } from "zustand";
import { persist } from "zustand/middleware";
import { fetchRulesApi } from "../api/ruleApi";
import { toast } from "react-toastify";

const initialState = {
  rules: [],
  form: {
    name: "",
    description: "",
    instructions: "",
    label: "",
    expressions: [],
    category: "",
    subcategory: "",
    tools: [],
    status: false,
    type: "",
    knowledgeBaseId: "",
  },
};

export const useRulesStore = create(
  persist(
    (set) => ({
      ...initialState,
      updateRules: (data) => {
        set((state) => ({
          rules: data,
        }));
      },
      fetchRules: async () => {
        try {
          const jsonRes = await fetchRulesApi();
          set((state) => ({ ...state, rules: jsonRes.data }));
        } catch (err) {
          console.log(err);
        }
      },
      updateForm: (key, val) => {
        set((state) => ({
          form: { ...state.form, [key]: val },
        }));
      },
      resetForm: () => {
        set((state) => ({
          form: initialState.form,
        }));
      },
      updateFullForm: (data) => {
        set((state) => ({
          form: data,
        }));
      },
      checkFormFilled: () => {
        const requiredFields = {
          name: "Name",
          description: "Description",
          instructions: "Instructions",
          // label: "Label",
          // category: "Category",
          // subcategory: "Subcategory",
          type: "Type",
          // knowledgeBaseId: "Knowledge Base",
        };
        let allFilled = true;
        let requiredString = "";
        set((state) => {
          Object.entries(requiredFields).forEach(([key, label]) => {
            if (!state.form[key]) {
              // toast.warn(`${label} is required.`);
              requiredString += `${label}, `;
              allFilled = false;
            }
          });
          return state;
        });
        if (!allFilled) {
          toast.warn(`${requiredString} are required.`);
        }
        return allFilled;
      },
    }),
    {
      name: "rule",
    },
  ),
);
