import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import RuleTable from "../components/RuleTable";
import SuggestionBar from "./components/SuggestionBar";
import TopBarContent from "./components/TopBarContent";
import { useNavigate } from "react-router-dom";
import { useRulesStore } from "../../../../store";

const RULE_TYPES = {
  assist: false,
  "self-serve": false,
  automation: false,
  HITL: false,
};

const RuleDisplay = () => {
  const navigate = useNavigate();
  const rulesStore = useRulesStore();
  const [rules, setRules] = useState([]);
  const [ruleTypes, setRuleTypes] = useState(RULE_TYPES);
  const [status, setStatus] = useState("all"); // all, active, inactive

  const handleStatusChange = (status) => {
    setStatus(status);
  };

  const handleRuleTypeChange = (key) => {
    setRuleTypes((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  useEffect(() => {
    setRules(rulesStore.rules);
  }, [rulesStore.rules]);

  useEffect(() => {
    const activeTypes = Object.entries(ruleTypes)
      .filter(([_, isActive]) => isActive)
      .map(([type]) => type);
    const filteredRules = rulesStore.rules?.filter((rule) => {
      const typeMatch =
        activeTypes.length === 0 || activeTypes.includes(rule.type);
      const statusMatch =
        status === "all"
          ? true
          : status === "active"
            ? rule.status === true
            : status === "inactive"
              ? rule.status === false
              : true;

      return typeMatch && statusMatch;
    });
    setRules(filteredRules);
  }, [ruleTypes, rulesStore.rules, status]);

  const handleOnClick = (id) => {
    navigate(`/dashboard/rules/view/${id}`);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <TopBar>
        <TopBarContent />
      </TopBar>
      <div className="flex-grow overflow-auto">
        <SuggestionBar
          handleRuleTypeChange={handleRuleTypeChange}
          ruleTypes={ruleTypes}
          handleStatusChange={handleStatusChange}
          status={status}
        />
        <RuleTable handleOnClick={handleOnClick} rules={rules} />
      </div>
    </div>
  );
};

export default RuleDisplay;
