import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useWebSocket } from "../../../../../hooks/useWebSocket";
import { useRulesStore, useAuthStore } from "../../../../../store";
import { useParams } from "react-router-dom";
import { getAllTonesApi } from "api/toneApi";
import DropdownBase from "components/selectDropdown";
const ChatLoader = () => (
  <div className="flex justify-center items-center p-4">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
  </div>
);

const TestRule = ({ open = false, onClose }) => {
  const [tones, setTones] = useState([]);
  const [selectedTone, setSelectTone] = useState("email");
  useEffect(() => {
    const fetchTones = async () => {
      try {
        const { data } = await getAllTonesApi();
        setTones(
          data.map((tone) => ({
            _id: tone.channel,
            name: tone.channel,
          })),
        );
      } catch (error) {
        console.error("Error fetching tones:", error);
      }
    };

    fetchTones();
  }, []);

  const { form, checkFormFilled } = useRulesStore();
  const { user } = useAuthStore();
  const { id } = useParams();
  const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;
  const { messages, sendMessage, isConnected, isWaiting } = useWebSocket({
    url: SOCKET_SERVER_URL,
  });

  useEffect(() => {
    console.log({ isWaiting });
  }, [isWaiting]);

  const [inputMessage, setInputMessage] = useState("");

  useEffect(() => {
    if (open) {
      const isFilled = checkFormFilled();
      if (!isFilled) {
        onClose();
      }
    }
  }, [open]);

  useEffect(() => {
    if (!isConnected && open) {
      onClose();
    }
  }, [isConnected, open]);

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      sendMessage({
        type: "TEST_RULE",
        payload: {
          messages: [
            {
              role: "user",
              content: inputMessage,
            },
          ],
          ruleId: id,
          accountId: user.accountId,
          channel: selectedTone,
        },
      });
      setInputMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div
      className={`fixed right-0 top-0 h-full bg-white shadow-lg z-[1002] transition-all duration-500 ease-in-out ${
        open ? "w-1/2" : "w-0"
      }`}
      id="test-rule-modal"
    >
      <div className="flex flex-col h-full pt-16">
        <div className="flex justify-between items-center p-4 bg-gray-100 absolute top-0 left-0 right-0">
          <h2 className="text-xl font-semibold">Test Rule</h2>
          <button
            className="text-gray-600 hover:text-gray-800"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          <div className="flex flex-col">
            <label htmlFor="selectedTone" className="mb-2 font-medium">
              Selected Tone
            </label>
            <DropdownBase
              id="selectedTone"
              options={tones}
              selectedOption={selectedTone}
              handleOptionChange={(tone) => setSelectTone(tone)}
              value="_id"
              label="name"
              className="w-64"
            />
          </div>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`p-3 rounded-lg ${
                message.from === "user" ? "bg-blue-100 ml-auto" : "bg-gray-100"
              }`}
            >
              <div className="font-semibold text-sm text-gray-600 mb-1">
                {message.from}
              </div>
              <div className="text-gray-800">
                <ReactMarkdown>{message.message}</ReactMarkdown>
              </div>
            </div>
          ))}
          {isWaiting && <ChatLoader />}
        </div>
        <div className="p-4 bg-gray-100">
          <div className="flex items-center">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              className="flex-1 border border-gray-300 rounded-l-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Type a message..."
            />
            <button
              onClick={handleSendMessage}
              className="bg-blue-500 text-white rounded-r-lg p-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestRule;
