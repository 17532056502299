import React, { useState, useEffect } from "react";
import { INTEGRATION_LIST } from "utils/constant.js";
import { fetchIntegrationsApi, connectIntegrationApi } from "api/shopifyApi.js"; // Import new API functions
import TopBar from "components/topBar.js";
import TopBarContent from "./components/topBarContent.js";

function IntegrationModal({
  isOpen,
  onClose,
  integration,
  onConnect,
  isConnecting,
}) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  useEffect(() => {
    setErrors({});
    return () => {
      setErrors({});
    };
  }, [integration]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check all fields for spaces and collect errors
    const newErrors = {};
    Object.entries(formData).forEach(([fieldName, value]) => {
      if (value.includes(" ")) {
        newErrors[fieldName] = "Spaces are not allowed";
      }
    });

    // If there are any errors, show them and prevent submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    await onConnect(integration, formData);
    onClose();
  };

  useEffect(() => {
    if (integration) {
      const initialFormData = integration.requiredFields.reduce(
        (acc, field) => {
          acc[field.name] = field.value || "";
          return acc;
        },
        {}
      );
      setFormData(initialFormData);
    }
    return () => {
      setFormData({});
    };
  }, [integration]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md w-full mx-4 transform transition-all duration-300 ease-in-out">
        <div className="flex items-center mb-6">
          <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mr-4">
            <img
              src={integration.logo}
              alt={`${integration.name} logo`}
              className="w-12 h-12 object-contain"
            />
          </div>
          <h2 className="text-2xl font-bold text-gray-800">
            {integration.name}
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          {integration.requiredFields.map((field) => {
            return (
              <div key={field.name}>
                <label
                  htmlFor={field.name}
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  {field.displayName}
                </label>
                <div className="flex items-center w-full border rounded">
                  <input
                    id={field.name}
                    name={field.name}
                    type="text"
                    value={
                      integration.isConnected
                        ? integration.shopName
                        : formData[field.name] || ""
                    }
                    onChange={handleInputChange}
                    placeholder={`Enter ${field.displayName}`}
                    className={`flex-grow p-3 border-none rounded focus:outline-none ${errors[field.name] ? "border-red-500" : ""}`}
                    required
                    disabled={integration.isConnected}
                  />
                  <div className="px-4 py-2 text-[#394DC6] border-l-gray-300 border-l">
                    .myshopify.com
                  </div>
                </div>
                {errors[field.name] && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors[field.name]}
                  </p>
                )}
              </div>
            );
          })}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800 border border-gray-300 rounded-md hover:bg-gray-50 transition duration-200 ease-in-out"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200 ease-in-out ${
                integration.isConnected || isConnecting
                  ? "cursor-not-allowed"
                  : ""
              }`}
              disabled={integration.isConnected || isConnecting}
              aria-label="Connect"
            >
              {isConnecting ? "Connecting..." : "Connect"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function IntegrationCard({
  integration,
  isConnected,
  onConnect,
  isLoading,
  isConnecting,
}) {
  return (
    <div className="flex-1 min-w-[200px] rounded-lg shadow-lg p-4 flex flex-col items-center bg-white">
      <img
        src={integration.logo}
        alt={`${integration.name} logo`}
        className="w-16 h-16 mb-2 object-contain"
      />
      <span className="text-lg font-semibold mb-2">{integration.name}</span>
      <button
        className={`px-4 py-2 rounded ${isConnected ? "bg-green-500 hover:bg-green-600" : "bg-blue-500 hover:bg-blue-600"} text-white`}
        onClick={onConnect}
        disabled={isLoading || isConnecting}
      >
        {isConnected
          ? "Connected"
          : isConnecting
            ? "Connecting..."
            : isLoading
              ? "Checking..."
              : "Connect"}
      </button>
    </div>
  );
}

function Integrations() {
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [integrationsList, setIntegrationsList] = useState(INTEGRATION_LIST);
  const [isChecking, setIsChecking] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const fetchIntegrations = async () => {
    setIsChecking(true);
    try {
      // TODO: Display for every integration will be different
      // TODO: This is scecific to shopify now
      const integrations = await fetchIntegrationsApi();
      const integrationsList = INTEGRATION_LIST.map((integration) => {
        const isConnected = integrations.some(
          (connectedIntegration) =>
            connectedIntegration.name === integration.name
        );
        return {
          ...integration,
          isConnected,
          shopName: integrations.find(
            (connectedIntegration) =>
              connectedIntegration.name === integration.name
          )?.authMetaData?.shop,
        };
      });
      setIntegrationsList(integrationsList);
      setIsChecking(false);
    } catch (error) {
      console.error("Error fetching integrations:", error);
    }
  };

  const openModal = (integration) => {
    setSelectedIntegration(integration);
  };

  const closeModal = () => {
    setSelectedIntegration(null);
  };

  const handleConnect = async (integration, formData) => {
    try {
      setIsConnecting(true);
      const response = await connectIntegrationApi(integration.name, formData);
      if (response.authUrl) {
        window.open(response.authUrl, "_blank");
      }
    } catch (error) {
      console.error("Error connecting integration:", error);
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <>
      <TopBar>
        <TopBarContent />
      </TopBar>
      <div className="p-4">
        <div className="flex flex-wrap gap-4">
          {integrationsList.map((integration) => (
            <IntegrationCard
              key={integration.name}
              integration={integration}
              isConnected={integration.isConnected}
              // onConnect={() => handleConnect(integration, {})}
              onConnect={() => openModal(integration)}
              isLoading={isChecking}
              isConnecting={isConnecting}
            />
          ))}
        </div>
      </div>
      <IntegrationModal
        isOpen={!!selectedIntegration}
        onClose={closeModal}
        integration={selectedIntegration}
        onConnect={handleConnect}
        isConnecting={isConnecting}
      />
    </>
  );
}

export default Integrations;
