import React, { useState } from "react";
import { updateResponseApi } from "../../../../api/responseApi";
import { toast } from "react-toastify";
import DropDownSVG from "../../../../assets/icons/dropdown.svg";

const QuestionBox = ({ sno, question, answer, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedAnswer, setEditedAnswer] = useState(answer);
  const [isSaving, setIsSaving] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await updateResponseApi(id, { answer: editedAnswer });
      toast.success("Response updated successfully");
    } catch (error) {
      console.error("Error saving answer:", error);
      toast.error("Something went wrong");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="mb-4">
      <div
        className="flex items-center p-4 cursor-pointer rounded-t-lg"
        onClick={toggleAccordion}
      >
        <span className="mr-4 font-bold">{sno}.</span>
        <h3 className="flex-grow font-semibold">{question}</h3>

        <img src={DropDownSVG} alt="dropdown" />
      </div>
      {isOpen && (
        <div className="p-4 bg-white">
          <textarea
            className="w-full p-2 border rounded mb-2"
            rows="4"
            value={editedAnswer}
            onChange={(e) => setEditedAnswer(e.target.value)}
          ></textarea>
          <div className="flex justify-end">
            <button
              className={`px-4 py-2 bg-highlightColor text-white hover:opacity-80 ${
                isSaving ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? (
                <span className="flex items-center">Saving...</span>
              ) : (
                "Save Response"
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionBox;
