import baseApi from "./baseApi";
import { useAuthStore } from "../store";

export const getAllResponsesApi = async () => {
  const { user, token } = useAuthStore.getState();
  if (!user) return;

  try {
    const response = await baseApi.get(`/response`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch responses");
    }

    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.error("Error fetching responses:", err);
    throw err;
  }
};

export const updateResponseApi = async (id, data) => {
  const { user, token } = useAuthStore.getState();
  if (!user) return;

  try {
    const response = await baseApi.put(`/response/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to update response");
    }

    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.error("Error updating response:", err);
    throw err;
  }
};
