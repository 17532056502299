import machineClientApi from "./machineClientApi";
import { useAuthStore } from "../store";

export const fetchIntegrationsApi = async () => {
  const { user, token } = useAuthStore.getState();
  if (!user) return;

  try {
    const response = await machineClientApi.get("/integrations/account", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.error("Error fetching integrations:", err);
    throw err;
  }
};

export const connectIntegrationApi = async (integrationName, formData) => {
  const { user, token } = useAuthStore.getState();
  if (!user) return;

  try {
    const response = await machineClientApi.get(
      `/${integrationName}/install?shop=${formData.shop}&accountId=${user.accountId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) throw Error("Api call failed");
    const jsonRes = await response.json();
    return jsonRes;
  } catch (err) {
    console.error("Error connecting integration:", err);
    throw err;
  }
};
