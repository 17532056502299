// src/pages/KnowledgeBase/index.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import KnowledgeBaseDisplay from "./KnowledgeBaseDisplay";
import CreateKnowledgeBase from "./CreateKnowledgeBase";
import EditKnowledgeBase from "./EditKnowledgeBase";
import ViewKnowledgeBase from "./ViewKnowledgeBase";

const KnowledgeBase = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<KnowledgeBaseDisplay />} />
        <Route path="/create" element={<CreateKnowledgeBase />} />
        <Route path="/edit/:id" element={<EditKnowledgeBase />} />
        <Route path="/view/:id" element={<ViewKnowledgeBase />} />
        <Route path="*" element={<KnowledgeBaseDisplay />} />
      </Routes>
    </>
  );
};

export default KnowledgeBase;
