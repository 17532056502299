import React from "react";
import RuleDisplay from "./RuleDisplay";
import CreateRule from "./CreateRule";
import EditRule from "./EditRule";
import ViewRule from "./ViewRule";
import { Routes, Route } from "react-router-dom";
import { useRulesStore } from "../../../store";

const Rules = () => {
  const { fetchRules } = useRulesStore();
  React.useEffect(() => {
    fetchRules();
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<RuleDisplay />} />
        <Route path="/create" element={<CreateRule />} />
        <Route path="/edit/:id" element={<EditRule />} />
        <Route path="/view/:id" element={<ViewRule />} />
        <Route path="*" element={<RuleDisplay />} />
      </Routes>
    </>
  );
};

export default Rules;
