import React from "react";
import TopBar from "../../../components/topBar";
import TopBarContent from "./components/topbarContent";
import MainSection from "./components/mainSection";

const Responses = () => {
  return (
    <>
      <div className="h-screenHeight">
        <TopBar>
          <TopBarContent />
        </TopBar>
        <MainSection />
      </div>
    </>
  );
};

export default Responses;
