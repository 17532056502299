import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import RuleCreationForm from "../components/RuleCreationForm";
import RuleToools from "../components/RuleTools";
import RuleCategoryForm from "../components/RuleCategoryForm";
import TopBarContent from "./components/TopBarContent";
import { useNavigate, useParams } from "react-router-dom";
import { useRulesStore } from "../../../../store";
import { updateRuleApi } from "../../../../api/ruleApi";
import { toast } from "react-toastify";

const EditRule = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    rules,
    form,
    resetForm,
    fetchRules,
    updateFullForm,
    checkFormFilled,
  } = useRulesStore();

  useEffect(() => {
    if (id === undefined || id === "") {
      resetForm();
      navigate("/dashboard/rules");
    }
    const rule = rules.find((rule) => rule._id === id);
    if (rule) {
      updateFullForm(rule);
    } else {
      resetForm();
      navigate("/dashboard/rules");
    }
    return () => {
      resetForm();
    };
  }, []);

  const handleSaveRule = async () => {
    try {
      const isFormFilled = checkFormFilled();
      if (!isFormFilled) {
        return;
      }
      await updateRuleApi(form, id);
      resetForm();
      fetchRules();
      toast.success("Rule updated successfully");
      navigate("/dashboard/rules");
    } catch {
      console.log("error");
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <TopBar>
        <TopBarContent handleSaveRule={handleSaveRule} />
      </TopBar>
      <div className="flex-grow overflow-auto">
        <RuleCreationForm />
        {/* <RuleCategoryForm /> */}
        <RuleToools />
      </div>
    </div>
  );
};

export default EditRule;
