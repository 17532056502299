import { useEffect, useState, useCallback, useRef } from "react";
import useWebSocketStore from "../store/websocketStore";
import { decodeMessage } from "utils";

export function useWebSocket({ url }) {
  const [messages, setMessages] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const initRef = useRef(false);
  const {
    initializeWebSocket,
    sendMessage: wsSendMessage,
    setMessageHandler,
    isConnected,
    error: connectionError,
  } = useWebSocketStore();

  useEffect(() => {
    if (!initRef.current) {
      initializeWebSocket(url);
      initRef.current = true;
    }

    const handleMessage = (data) => {
      try {
        const parsed = JSON.parse(data);
        const resp = parsed.payload;

        if (resp?.success) {
          const responseDecoded = decodeMessage(resp?.response?.[0]);
          setMessages((prev) => [
            ...prev,
            { from: "Copilot", message: responseDecoded },
          ]);
        } else {
          setMessages((prev) => [
            ...prev,
            { from: "Copilot", message: resp?.error || "An error occurred" },
          ]);
        }
      } catch (error) {
        console.error("Error processing message:", error);
        setMessages((prev) => [
          ...prev,
          {
            from: "Copilot",
            message: "An error occurred while processing the message",
          },
        ]);
      } finally {
        setIsWaiting(false);
      }
    };

    setMessageHandler(handleMessage);

    return () => {
      setMessageHandler(null);
    };
  }, [url, initializeWebSocket, setMessageHandler]);

  const sendMessage = useCallback(
    (message) => {
      setIsWaiting(true);
      setMessages((prev) => [
        ...prev,
        {
          from: "You",
          message: message?.payload?.messages[0].content,
        },
      ]);
      wsSendMessage(message);
    },
    [wsSendMessage],
  );

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  return {
    messages,
    sendMessage,
    isConnected,
    clearMessages,
    isWaiting,
    error: connectionError,
  };
}
